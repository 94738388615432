<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">SpookApp</h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">Adventure starts here 🚀</p>
          <p class="mb-2">Make your app management easy and fun!</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              placeholder="john@example.com"
              class="mb-3"
              :rules="emailRules"
              hide-details="auto"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details="auto"
              :rules="[rules.required, rules.min]"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field
            ><br />
            <v-text-field
              v-model="repassword"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details="auto"
              :rules="[rules.required, rules.min, passwordConfirmationRule]"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-checkbox class="mt-1" required hide-details="auto" :rules="[v => !!v || 'You must agree to continue!']">
              <template #label>
                <div class="d-flex align-center flex-wrap">
                  <span class="me-2">I agree to</span><a href="javascript:void(0)">privacy policy &amp; terms</a>
                </div>
              </template>
            </v-checkbox>

            <v-btn block color="primary" class="mt-6" :disabled="isDisabled" @click="submitForm"> Sign Up </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <p>{{ $t('message') }}</p>
          <span class="me-2"> Already have an account? </span>
          <router-link :to="{ name: 'pages-login' }"> Sign in instead </router-link>
        </v-card-text>

        <!-- divider -->
        <!--   <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text> -->

        <!-- social link -->
        <!--       <v-card-actions class="d-flex justify-center">
          <v-btn rounded dense outlined small icon class="me-n3 mt-n1" @click="createGoogleAccount()">
            <v-icon color="#db4437">
              {{ icons.mdiGoogle }}
            </v-icon>
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from 'firebase/auth'
import { getNameFromEmail } from '../../../generalUtils'
import { set } from 'firebase/database'

import { collection, addDoc } from 'firebase/firestore'
import { dbRef, signupGoogle, signupEmail } from '../../../firebaseConfig'
import i18n from '@/i18n'
const auth = getAuth()
export default {
  computed: {
    passwordConfirmationRule() {
      return this.password === this.repassword || 'Password should match'
    },
    isDisabled() {
      // evaluate whatever you need to determine disabled here...
      return !this.valid
    },
  },
  methods: {
    submitForm() {
      signupEmail()
      let value
      let myerror
      i18n.locale = 'es'
      const result = this.$refs.form.validate()
      if (result == true) {
        this.$store.commit('LOADER', true);
        createUserWithEmailAndPassword(auth, this.email, this.password)
          .then(userCredential => {
            const { user } = userCredential
            console.log('theCreate Account')
            console.log(user)
            console.log(user.uid)
            console.log(dbRef)
            if (!user.displayName) {
              updateProfile(auth.currentUser, {
                displayName: getNameFromEmail(user.email),
              })
                .then(() => {
                  // Profile updated!
                  // ...
                  console.log('Update Name')
                  user.displayName = getNameFromEmail(user.email)
                })
                .catch(error => {
                  // An error occurred
                  // ...
                  console.log(error)
                  console.log('Update Name failed')
                })
                .finally(res => {
                  this.$store.commit('LOADER', false)
                })
            }
            // set(ref(dbRef, '/temp/'), {
            //   uid: user.uid,
            //   email: user.email,
            //   emailVerified: user.emailVerified,
            // })
            addDoc(collection(dbRef, 'users'), {
              uid: user.uid,
              email: user.email,
              emailVerified: user.emailVerified,
            })
              .then(result => {
                console.log(result)

                // Data saved successfully!
                if (localStorage) {
                  localStorage.setItem('emailForSignIn', JSON.stringify(user))
                }

                myerror =
                  'If you do not receive a confirmation email, please check your spam folder. Also, please verify that you entered a valid email address in our sign-up form.'
                sendEmailVerification(auth.currentUser)
                  .then(result => {
                    console.log(result)
                    value = true
                    myerror =
                      'If you do not receive a confirmation email, please check your spam folder. Also, please verify that you entered a valid email address in our sign-up form.'

                    // this.email = ''
                    // this.password = ''
                    this.swal(value, myerror)

                    // this.signUpCheckModel = true;
                  })
                  .catch(error => {
                    value = false
                    myerror = error.message
                    this.swal(false, error.message)
                  })
                  .finally(res => {
                    this.$store.commit('LOADER', false)
                  })
              })
              .catch(error => {
                // The write failed...
                console.log(error)
                this.swal(false, error.message)
              })
              .finally(res => {
                this.$store.commit('LOADER', false)
              })
          })
          .catch(error => {
            const errorCode = error.code
            const errorMessage = error.message
            console.log(error)
            this.swal(false, error.message)
          })
          .finally(res => {
            this.$store.commit('LOADER', false)
          })
      }
    },
    swal(value, error) {
      //  console.log(constant.error.hello);
      if (value == true) {
        if (error == '' || error == undefined) {
          this.$swal.fire('success', 'Email send successfully', 'success')
        } else {
          this.$swal.fire('success', error, 'success')
        }
      } else if (value == null) {
        //  console.log("null");
      } else {
        // console.log("value get" + error);
        if (error == '') {
          //   console.log("null value get" + error);
          this.$swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        } else {
          //  console.log("not null value get" + error);
          this.$swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          })
        }
      }
    },
    createGoogleAccount() {
      console.log('createGoogleAccount ')
      const auth = getAuth()
      const provider = new GoogleAuthProvider()
      signInWithPopup(auth, provider)
        .then(result => {
          const { user } = result
          console.log(result)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },
    createAccount() {},
  },
  setup() {
    const isPasswordVisible = ref(false)
    const username = ref('')
    const email = ref('')
    const password = ref('')
    const repassword = ref('')
    false
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      username,
      email,
      password,
      repassword,
      socialLink,
      valid: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 5 || 'Password must be more than 4 characters',
        emailMatch: () => "The email and password you entered don't match",
      },
      emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 5) || 'Password must be more than 4 characters',
      ],
      validateUser: {
        emailVerified: '',
      },
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiGoogle,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
